import { Flex, FlexItem, Text, TextVariants } from '@patternfly/react-core';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useBreadcrumbs, { BreadcrumbData, BreadcrumbsRoute } from 'use-react-router-breadcrumbs';
import { GenericKeyValueResponse, GenericStringKeyValueResponse } from '../api/types';
import { constructUrl, parseFolderSegments } from '../helpers/folder-url-helper';
import { useMount } from 'react-use';

const BreadCrumbs = () => {
	const pathname = useLocation().pathname;
	const segments = pathname.split('/'); // Split the URL by '/'
	const isDataframes = pathname.includes('analyze/dataframes');
	const isCharts = pathname.includes('analyze/charts');
	const isTables = pathname.includes('analyze/table') || pathname.includes('/table/');
	const isReports = pathname.includes('/report');
	const isDashboards = pathname.includes('analyze/dashboards');
	const isReportsRecents = pathname == '/report/recents';
	const isReportsFavourites = pathname == '/report/favorites';
	const isStandardFlow = isCharts || isDataframes || isReports || isTables || isDashboards;
	const [segmentedDataframeBreadcrumb, setSegmentedDataframeBreadcrumb] = useState<
		GenericKeyValueResponse<string | number>[]
	>([]);
	const [segmentedStandardBreadcrumb, setSegmentedStandardBreadcrumb] = useState<
		GenericKeyValueResponse<string | number>[]
	>([]);

	const folderName =
		Number.isInteger(Number(segments[segments.length - 1])) && segments.includes('folder')
			? decodeURI(segments[segments.length - 2])
			: 'Folder';

	const nestedFoldersBreadcrumb = localStorage.getItem('currentNestedFolderPath') ?? 'Create';

	useMount(() => {
		const nestedFoldersBreadcrumb_Dataframes = localStorage.getItem('prevDataframePage') ?? '';
		const segmentedDataframesBreadcrumb = parseFolderSegments(
			nestedFoldersBreadcrumb_Dataframes
		);
		const nestedFoldersBreadcrumb_Charts =
			localStorage.getItem('currentNestedFolderPath') ?? '';
		const segmentedDChartsBreadcrumb = parseFolderSegments(nestedFoldersBreadcrumb_Charts);

		setSegmentedDataframeBreadcrumb(segmentedDataframesBreadcrumb);
		setSegmentedStandardBreadcrumb(segmentedDChartsBreadcrumb);
	});

	useEffect(() => {
		if (isDataframes) {
			updateDataframeBreadcrumb();
		}
		if (isStandardFlow) {
			updateStandardBreadcrumb();
		}

		//un-hide any breadcrumbs that were previously hidden by other actions
		const cleanupElements = document.getElementsByClassName('temp-hidden');

		Array.from(cleanupElements).forEach((element, index) => {
			const htmlElement = element as HTMLElement;
			htmlElement.classList.remove('temp-hidden');
		});

		//look for GUIDs and hide any breadcrumbs that contain a guid
		const breadcrumbElements = document.getElementsByClassName('breadcrumb-item-box');
		const guidRegex =
			/^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;

		Array.from(breadcrumbElements).forEach((element, index) => {
			const htmlElement = element as HTMLElement;
			const text = htmlElement.innerText.replaceAll(' ', '-').trim();
			if (guidRegex.test(text)) {
				//the html structure of the breadcrumbs items never changes so it will always be 3 parent elements up
				const parentElement = htmlElement.parentElement?.parentElement?.parentElement;

				if (parentElement && !parentElement.classList.contains('temp-hidden')) {
					parentElement.classList.add('temp-hidden');
				}
			}
		});
	}, [window.location.pathname]);

	const updateDataframeBreadcrumb = () => {
		const urlToUse = localStorage.getItem('prevDataframePage') ?? pathname;
		const newDataframesBreadcrumb = parseFolderSegments(urlToUse).filter(
			(x) => x.value != 'View'
		);

		const breadcrumbListContainsCreate =
			newDataframesBreadcrumb.find((x) => x.value == 'Create') !== undefined;
		const breadcrumbListContainsEdit =
			newDataframesBreadcrumb.find((x) => x.value == 'Edit') !== undefined;
		const breadcrumbListContainsView =
			newDataframesBreadcrumb.find((x) => x.value == 'View') !== undefined;

		if (pathname.includes('create') && !breadcrumbListContainsCreate) {
			setSegmentedDataframeBreadcrumb([
				...newDataframesBreadcrumb,
				{ id: 0, value: 'Create' },
			]);
		} else if (pathname.includes('edit') && !breadcrumbListContainsEdit) {
			setSegmentedDataframeBreadcrumb([...newDataframesBreadcrumb, { id: 0, value: 'Edit' }]);
		} else if (pathname.includes('view') && !breadcrumbListContainsView) {
			setSegmentedDataframeBreadcrumb([...newDataframesBreadcrumb, { id: 0, value: 'View' }]);
		} else {
			setSegmentedDataframeBreadcrumb(newDataframesBreadcrumb);
		}
	};

	const updateStandardBreadcrumb = () => {
		setTimeout(() => {
			const currentFolderPath = localStorage.getItem('currentNestedFolderPath');
			const urlToUse = currentFolderPath != null ? currentFolderPath : pathname;

			if (urlToUse) {
				const newChartsBreadcrumb = parseFolderSegments(urlToUse).filter(
					(x) => x.value != 'View'
				);

				const breadcrumbListContainsCreate =
					newChartsBreadcrumb.find((x) => x.value == 'Create') !== undefined;
				const breadcrumbListContainsEdit =
					newChartsBreadcrumb.find((x) => x.value == 'Edit') !== undefined;
				const breadcrumbListContainsView =
					newChartsBreadcrumb.find((x) => x.value == 'View') !== undefined;

				const urlEndsInNumber = /\/\d+$/.test(pathname);

				if (
					(pathname.includes('create') || pathname.includes('build/dataframe/')) &&
					!breadcrumbListContainsCreate
				) {
					setSegmentedStandardBreadcrumb([
						...newChartsBreadcrumb,
						{ id: 0, value: 'Create' },
					]);
				} else if (
					(pathname.includes('build') ||
						pathname.includes('edit') ||
						(urlEndsInNumber &&
							!pathname.includes('view') &&
							!pathname.includes('/analyze/table/') &&
							!pathname.includes('/folder/'))) &&
					!pathname.includes('build/dataframe/') &&
					!breadcrumbListContainsEdit
				) {
					setSegmentedStandardBreadcrumb([
						...newChartsBreadcrumb,
						{ id: 0, value: 'Edit' },
					]);
				} else if (pathname.includes('view') && !breadcrumbListContainsView) {
					setSegmentedStandardBreadcrumb([
						...newChartsBreadcrumb,
						{ id: 0, value: 'View' },
					]);
				} else {
					setSegmentedStandardBreadcrumb(newChartsBreadcrumb);
				}
			} else {
				setSegmentedStandardBreadcrumb([]);
			}
		}, 550);
	};

	const breadcrumbs = useBreadcrumbs([
		{
			path: '/analyze/dashboards/blank',
			breadcrumb: 'New Dashboard',
		},
		{
			path: '/analyze',
			children: [
				{
					path: 'folder',
					breadcrumb: null,
				},
				{
					path: 'folder/:foldername',
					breadcrumb: null,
				},
				{
					path: 'folder/:foldername/:id',
					breadcrumb: folderName,
				},
				{
					path: 'charts/folder/*folderPath',
					breadcrumb: pathname,
				},
				{
					path: 'dataframes',
					breadcrumb: 'Dataframes',
				},
				{
					path: 'dataframes/create',
					breadcrumb: nestedFoldersBreadcrumb,
				},
				{
					path: 'dataframes/folder/*folderPath',
					breadcrumb: segmentedDataframeBreadcrumb,
				},
				{
					path: 'dataframes/edit',
					breadcrumb: null,
				},
				{
					path: 'dataframes/edit/:id',
					breadcrumb: 'Edit',
				},
				{
					path: 'charts',
					breadcrumb: 'Charts',
				},
				{
					path: 'charts/build',
					breadcrumb: null,
				},
				{
					path: 'charts/folder',
					breadcrumb: null,
				},
				{
					path: 'charts/folder/:foldername',
					breadcrumb: null,
				},
				{
					path: 'charts/folder/:foldername/:id',
					breadcrumb: folderName,
				},
				{
					path: 'charts/view/chart/*',
					breadcrumb: nestedFoldersBreadcrumb,
				},
				{
					path: 'charts/build/chart/*',
					breadcrumb: nestedFoldersBreadcrumb,
				},
				{
					path: 'dashboards',
					breadcrumb: 'Dashboards',
					children: [
						{
							path: 'view',
							breadcrumb: 'View',
							children: [
								{
									path: ':id',
									breadcrumb: null,
								},
							],
						},
						{
							path: ':dashboardId',
							breadcrumb: 'Edit',
							children: [
								{
									path: 'chart',
									breadcrumb: 'Chart Widget',
									children: [
										{
											path: ':chartId',
											breadcrumb: null,
										},
										{
											path: 'dataframe/:dataframeId',
											breadcrumb: null,
										},
										{
											path: 'key-measure/:measureId',
											breadcrumb: null,
										},
										{
											path: 'key-measure-fact/:measureFactId',
											breadcrumb: null,
										},
									] as BreadcrumbsRoute[],
								},
							] as BreadcrumbsRoute[],
						},
					] as BreadcrumbsRoute[],
				},

				{
					path: 'charts/build/dataframe',
					breadcrumb: null,
				},
				{
					path: 'charts/build/dataframe/:id',
					breadcrumb: nestedFoldersBreadcrumb,
				},
				{
					path: 'charts/build/chart',
					breadcrumb: null,
				},
				{
					path: 'charts/build/chart/:chartId',
					breadcrumb: 'Edit',
				},
				{
					path: 'charts/build/key-measure-fact',
					breadcrumb: null,
				},
				{
					path: 'charts/build/key-measure-fact/:id',
					breadcrumb: null,
				},
				{
					path: 'charts/build/key-measure-fact/:id/:id',
					breadcrumb: 'Create',
				},
			],
		},
		{
			path: '/setup',
			breadcrumb: 'Setup',
		},
		{
			path: '/report',
			children: [
				{
					path: 'folder',
					breadcrumb: null,
				},
				{
					path: 'folder/:foldername',
					breadcrumb: null,
				},
				{
					path: 'folder/:foldername/:id',
					breadcrumb: folderName,
				},
				{ path: 'edit', breadcrumb: null },
				{
					path: 'edit/:id',
					breadcrumb: 'Edit',
				},
			],
		},
		{
			path: '/present',
			children: [
				{
					path: 'folder',
					breadcrumb: null,
				},
				{
					path: 'folder/:foldername',
					breadcrumb: null,
				},
				{
					path: 'folder/:foldername/:id',
					breadcrumb: folderName,
				},
				{ path: 'edit', breadcrumb: null },
				{
					path: 'edit/:presentationId',
					breadcrumb: 'Edit',
				},
			],
		},
		{
			path: '/analyze/dashboard',
			children: [
				{
					path: ':id',
					breadcrumb: 'View',
				},
			],
		},
	] as BreadcrumbsRoute[]);
	const navigate = useNavigate();

	const CleanFoldersLocalStorage = () => {
		localStorage.removeItem('currentSelectedFolderId');
		localStorage.removeItem('prevAnalyzePage');
		localStorage.removeItem('prevDataframePage');
	};

	const breadcrumbItemClick = (item: BreadcrumbData<string>) => {
		CleanFoldersLocalStorage();
		const link = item.match.pathname;

		if (link == '/setup/security') {
			navigate('/setup');
		} else if (link.includes('/setup/security/user')) {
			navigate('/setup/security/users');
		} else if (link.includes('/setup/subscription')) {
			navigate('/setup/subscription/builder');
		} else {
			navigate(item.match.pathname);
		}
	};

	const DataframeBreadcrumbItemClick = (item: GenericKeyValueResponse<string | number>) => {
		if (item.id == 0) {
			return;
		}

		CleanFoldersLocalStorage();
		const link = `/analyze/dataframes/folder/${item.value}/${item.id}`;
		navigate(link);
	};

	function removeDuplicateSlashes(url: string): string {
		// Ensure we don't remove the protocol part (e.g., https://)
		return url.replace(/([^:]\/)\/+/g, '$1');
	}

	const StandardBreadcrumbItemClick = (item: GenericKeyValueResponse<string | number>) => {
		if (item.id == 0) {
			return;
		}

		const selectedSegment = segmentedStandardBreadcrumb.find((x) => x.id == item.id);
		if (selectedSegment) {
			localStorage.setItem('isBreadcrumbFolderClicked', 'True');
			const nestedFolderPath = localStorage.getItem('currentNestedFolderPath');
			const selectedSegmentIdx = segmentedStandardBreadcrumb.indexOf(selectedSegment);

			const type = getSection(pathname);
			let link = `/analyze/${type != 'dashboards' ? type : ''}`;

			if (selectedSegmentIdx == 0) {
				link += `/folder/${item.value}/${item.id}`;
			} else {
				link = constructUrl(nestedFolderPath!, selectedSegmentIdx);
			}

			link = removeDuplicateSlashes(link);

			navigate(link);
		}
	};

	const BaseBreadcrumbItemClick = (item: GenericStringKeyValueResponse<string>) => {
		CleanFoldersLocalStorage();

		if (item.key == 'Dataframes') {
			navigate('/analyze/loading');
			setTimeout(() => {
				navigate(item.value);
			}, 250);
		} else if (item.key == 'Dashboards') {
			localStorage.removeItem('currentNestedFolderPath');
			navigate(item.value);
		} else {
			navigate(item.value);
		}
	};

	function decodeBreadcrumb(breadcrumb: React.ReactNode): React.ReactNode {
		if (React.isValidElement(breadcrumb)) {
			const element = breadcrumb as React.ReactElement;
			return React.cloneElement(element, {
				// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-assignment
				children: React.Children.map(element.props.children, (child) =>
					// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-return
					typeof child === 'string' ? decodeURIComponent(child) : child
				),
			});
		}
		return breadcrumb;
	}

	function getSection(url: string): string {
		const start = url.indexOf('analyze/') + 'analyze/'.length;
		const end = url.indexOf('/', start);

		return end === -1 ? url.substring(start) : url.substring(start, end);
	}

	if (isDataframes) {
		const baseSegments: GenericStringKeyValueResponse<string>[] = [
			{ key: 'Home', value: '/' },
			{ key: 'Analyze', value: '/analyze' },
			{ key: 'Dataframes', value: '/analyze/dataframes' },
		];

		return (
			<React.Fragment>
				<Flex
					direction={{ default: 'row' }}
					className="breadcrumbs"
				>
					{/* BASE SEGMENTS */}
					{baseSegments.map((item, index) => {
						const isLastItem = index === baseSegments.length - 1;
						const isListing = pathname == '/analyze/dataframes';

						return (
							<Flex
								key={index}
								spaceItems={{ default: 'spaceItemsSm' }}
								justifyContent={{ default: 'justifyContentSpaceBetween' }}
							>
								<FlexItem>
									<Text
										component={TextVariants.a}
										onClick={() => {
											BaseBreadcrumbItemClick(item);
										}}
									>
										{item.key}
									</Text>
								</FlexItem>
								{isListing && !isLastItem && <FlexItem>/</FlexItem>}
								{!isListing && <FlexItem>/</FlexItem>}
							</Flex>
						);
					})}

					{/* DYNAMIC DATAFRAME SEGMENTS */}
					{segmentedDataframeBreadcrumb.map((item, index) => {
						const isLastItem = index === segmentedDataframeBreadcrumb.length - 1;

						return (
							<Flex
								key={index}
								spaceItems={{ default: 'spaceItemsSm' }}
								justifyContent={{ default: 'justifyContentSpaceBetween' }}
							>
								<FlexItem>
									<Text
										component={isLastItem ? TextVariants.p : TextVariants.a}
										onClick={() => {
											DataframeBreadcrumbItemClick(item);
										}}
									>
										{decodeURIComponent(item.value.toString())}
									</Text>
								</FlexItem>
								{!isLastItem && <FlexItem>/</FlexItem>}
							</Flex>
						);
					})}
				</Flex>
			</React.Fragment>
		);
	} else if (isStandardFlow) {
		const baseSegments: GenericStringKeyValueResponse<string>[] = [{ key: 'Home', value: '/' }];

		if (isCharts) {
			baseSegments.push({ key: 'Analyze', value: '/analyze' });
			baseSegments.push({ key: 'Charts', value: '/analyze/charts' });
		}

		if (isTables) {
			baseSegments.push({ key: 'Analyze', value: '/analyze' });
			baseSegments.push({ key: 'Tables', value: '/analyze/tables' });
		}

		if (isReports) {
			baseSegments.push({ key: 'Reports', value: '/report' });

			if (isReportsRecents) {
				baseSegments.push({ key: 'Recents', value: '/report/recents' });
			}

			if (isReportsFavourites) {
				baseSegments.push({ key: 'Favorites', value: '/report/favorites' });
			}
		}

		if (isDashboards) {
			baseSegments.push({ key: 'Analyze', value: '/analyze' });
			baseSegments.push({ key: 'Dashboards', value: '/analyze/dashboards' });
		}

		return (
			<React.Fragment>
				<Flex
					direction={{ default: 'row' }}
					className="breadcrumbs"
				>
					{/* BASE SEGMENTS */}
					{baseSegments.map((item, index) => {
						const isLastItem = index === baseSegments.length - 1;
						const isListing = pathname == baseSegments[baseSegments.length - 1].value;

						return (
							<Flex
								key={index}
								spaceItems={{ default: 'spaceItemsSm' }}
								justifyContent={{ default: 'justifyContentSpaceBetween' }}
							>
								<FlexItem>
									<Text
										component={
											(item.key == 'Recents' && isReportsRecents) ||
											(item.key == 'Favorites' && isReportsFavourites)
												? TextVariants.p
												: TextVariants.a
										}
										onClick={() => {
											BaseBreadcrumbItemClick(item);
										}}
									>
										{item.key}
									</Text>
								</FlexItem>
								{isListing && !isLastItem && <FlexItem>/</FlexItem>}
								{!isListing && <FlexItem>/</FlexItem>}
							</Flex>
						);
					})}

					{/* DYNAMIC CHART SEGMENTS */}
					{segmentedStandardBreadcrumb.map((item, index) => {
						const isLastItem = index === segmentedStandardBreadcrumb.length - 1;

						return (
							<Flex
								key={index}
								spaceItems={{ default: 'spaceItemsSm' }}
								justifyContent={{ default: 'justifyContentSpaceBetween' }}
							>
								<FlexItem>
									<Text
										component={isLastItem ? TextVariants.p : TextVariants.a}
										onClick={() => {
											StandardBreadcrumbItemClick(item);
										}}
									>
										{decodeURIComponent(item.value.toString())}
									</Text>
								</FlexItem>
								{!isLastItem && <FlexItem>/</FlexItem>}
							</Flex>
						);
					})}
				</Flex>
			</React.Fragment>
		);
	} else {
		return (
			<React.Fragment>
				<Flex
					direction={{ default: 'row' }}
					className="breadcrumbs"
				>
					{breadcrumbs.map((item, index) => {
						const isLastItem = index === breadcrumbs.length - 1;

						return (
							<Flex
								key={index}
								spaceItems={{ default: 'spaceItemsSm' }}
								justifyContent={{ default: 'justifyContentSpaceBetween' }}
							>
								<FlexItem>
									<Text
										component={isLastItem ? TextVariants.p : TextVariants.a}
										onClick={() => {
											breadcrumbItemClick(item);
										}}
									>
										<div className="breadcrumb-item-box">
											{decodeBreadcrumb(item.breadcrumb)}
										</div>
									</Text>
								</FlexItem>
								{!isLastItem && <FlexItem>/</FlexItem>}
							</Flex>
						);
					})}
				</Flex>
			</React.Fragment>
		);
	}
};

export default BreadCrumbs;
