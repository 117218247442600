import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useBlocker, useOutletContext, useParams } from 'react-router-dom';
import { DashboardLayoutType } from './Dashboard';
import {
	AlertVariant,
	Button,
	Card,
	CardBody,
	Flex,
	FlexItem,
	FormGroup,
	Grid,
	GridItem,
	TextInput,
} from '@patternfly/react-core';
import { Dashboard } from '../../../api/dashbboards/Dashboards';
import { DashboardWidget, TDashboardWidget } from '../../../api/dashbboards/DashboardWidgets';
import { useMount } from 'react-use';
import DropGrid from '../../../components/dnd/DropGrid';
import { useToast } from '@zeroedin-tech/zi-common-ui/lib';
import Loader from '../../../components/util/Loader';

import ZiChartOptionsModal, {
	ZiChartOptionsModalProps,
} from '../../../components/modals/charts/ZiChartOptionsModal';
import { GridBorderOutletContext, GridLayoutOutletContext } from '../../../layout/Layout';
import { Folder, TFolder } from '../../../api/foundational-elements/Folder';
import ExclamationCircleIcon from '@patternfly/react-icons/dist/esm/icons/exclamation-circle-icon';
import { addNewRecentDashboard } from '../../../helpers/helper-components/recents-factory-helper';
import { DashboardFilter } from '../../../api/dashbboards/DashboardFilter';
import DashboardFilters from './DashboardFilters';
import { TNewDateRange } from '../../../api/types/TNewDateRange';
import PageTitleSubheader from '../../../layout/subheader/PageTitleSubheader';
import WidgetLibrary from '../../../components/dnd/widgets/WidgetLibrary';
import EditChartModal from '../../../helpers/helper-components/DeleteConfirmationModal';
import SelectFolderDropdown from '../../../helpers/helper-components/SelectFolderDropdown';
import { useLocation } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons';

type Props = {
	type: DashboardLayoutType;
};
const DashboardDetails = (_props: Props) => {
	const { gridLayout } = useOutletContext<GridLayoutOutletContext>();
	const { gridBorder } = useOutletContext<GridBorderOutletContext>();
	const { dashboardId } = useParams();
	const navigate = useNavigate();
	const { addToast } = useToast();
	const [unSavedChanges, setUnSavedChanges] = useState(false);
	const [folders, setFolders] = useState<TFolder[]>([]);
	const [loading, setLoading] = useState<boolean>(false);
	const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false);
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
	const [dashboardModel, setDashboardModel] = useState<Dashboard>({
		...Dashboard.Default(),
	});
	const [existingDashboardName, setExistingDashboardName] = useState<string>();
	const [widget, setWidget] = useState<DashboardWidget>();
	const [widgets, setWidgets] = useState<DashboardWidget[]>([]);
	const [widgetsToDelete, setWidgetsToDelete] = useState<DashboardWidget[]>([]);
	const [dashboardFilters, setDashboardFilters] = useState<DashboardFilter[] | undefined>([]);
	const [dashboardFiltersToDelete, setDashboardFiltersToDelete] = useState<
		DashboardFilter[] | undefined
	>([]);
	const [selectedDate, setSelectedDate] = useState<TNewDateRange>();
	const { setSubSide, subNavExpanded, setSubNavExpanded, updateGridLayout, updateGridBorder } =
		useOutletContext<GridLayoutOutletContext>();
	const queryParams = useLocation().search;
	const isCopy = queryParams.includes('isCopy=true');
	// validation
	const [validated, setValidated] = useState<
		'default' | 'warning' | 'success' | 'error' | undefined
	>('default');

	const updateSelectedDate = useCallback(
		(startDateRange: TNewDateRange, endDateRange: TNewDateRange) => {
			if (setSelectedDate) {
				setSelectedDate({
					begin_date: startDateRange.begin_date,
					end_date: endDateRange.end_date,
					period: startDateRange.period,
					sequence: startDateRange.sequence,
				});
			}
		},
		[selectedDate, setSelectedDate]
	);

	useMount(() => {
		Folder.GetAll({ type: 'dashboards' })
			.then((response) => {
				const filteredFolders = response.filter((f) => f.type === 'folders' && f.isFolder);

				if (filteredFolders && filteredFolders.length > 0) {
					setFolders(filteredFolders);
				} else {
					setFolders([
						{
							id: -1,
							name: 'No folders found',
							type: 'dashboard',
							items: [],
						},
					]);
				}
			})
			.catch(() => {
				addToast('Get folders failed.', AlertVariant.danger);
			});
		if (dashboardId) {
			setLoading(true);
			Dashboard.Get(parseInt(dashboardId))
				.then((dashboardResponse) => {
					if (dashboardResponse) {
						setDashboardModel({ ...dashboardResponse });
						setExistingDashboardName(dashboardResponse.name);

						if (!isCopy) {
							setValidated('success');
						} else {
							setValidated('error');
						}

						DashboardWidget.GetAll({ dashboard: dashboardId }, ['conditionalRules'])
							.then((widgetsResponse) => {
								setWidgets(widgetsResponse);
								setLoading(false);
							})
							.catch(() => {
								addToast('Get all widgets failed.', AlertVariant.danger);
							});
					}
				})
				.catch(() => {
					addToast('Get dashboard failed.', AlertVariant.danger);
				});

			//log entry into recents for loaded dashboard
			addNewRecentDashboard(dashboardId);
		}
	});

	useEffect(() => {
		if (
			(dashboardId && dashboardModel.hasBorders === false) ||
			dashboardModel.hasLayout === false
		) {
			createSubSide(dashboardModel.hasBorders, dashboardModel.hasLayout);
		} else if (!dashboardId) {
			createSubSide();
		}
	}, [setSubSide, subNavExpanded, setSubNavExpanded]);

	useEffect(() => {
		if (widget?.isEdit) {
			navigateToPage();
		}
	}, [dashboardModel?.id, widget?.isEdit]);

	useEffect(() => {
		const handleBeforeUnload = (e: any) => {
			if (unSavedChanges) {
				// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
				e.preventDefault();
				// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
				e.returnValue = '';
				return true;
			}
			return null;
		};

		window.addEventListener('beforeunload', handleBeforeUnload);

		return () => window.removeEventListener('beforeunload', handleBeforeUnload);
	}, [unSavedChanges]);

	const shouldBlockNavigation = () => {
		if (unSavedChanges) {
			setIsEditModalOpen(true);
			setUnSavedChanges(false);
			return true;
		}
		return false;
	};

	const blocker = useBlocker(shouldBlockNavigation);

	const createSubSide = (hasBorders?: boolean, hasLayout?: boolean) => {
		setSubSide({
			subheaderContext: (
				<PageTitleSubheader
					pageTitle={''}
					expanded={subNavExpanded}
					setExpanded={setSubNavExpanded}
					isCollapsable
				/>
			),
			hideLeftSideBar: true,
			rightSideBar: (
				<WidgetLibrary
					isDashboard
					gridLayout={hasLayout}
					gridBorder={hasBorders}
					updateGridLayout={updateGridLayout}
					updateGridBorder={updateGridBorder}
				/>
			),
		});
	};

	const handleNameChange = (value: string, _event: React.FormEvent<HTMLInputElement>) => {
		setDashboardModel({ ...dashboardModel, name: value });
		!unSavedChanges && setUnSavedChanges(true);

		if (value === '') {
			setValidated('error');
		} else {
			setValidated('success');
		}
	};

	const removeWidget: (widget: DashboardWidget, save?: boolean) => void = (
		widget: DashboardWidget,
		save?: boolean
	) => {
		removeWidgetAsync(widget, save).catch(() => {
			addToast('Widget remove failed.', AlertVariant.danger);
		});
	};

	const removeWidgetAsync = async (widget: DashboardWidget, save?: boolean) => {
		!unSavedChanges && setUnSavedChanges(true);

		if (widget.id && !widget.isNew && save) {
			try {
				await DashboardWidget.Delete(widget.id);
				setWidgets((widgets) => [...widgets.filter((w) => w.id != widget.id)]);
			} catch (error) {
				addToast('Widget remove failed.', AlertVariant.danger);
			}
		} else {
			setWidgetsToDelete((widgets) => [...widgets, widget]);
			setWidgets((widgets) => [...widgets.filter((w) => w.id != widget.id)]);
		}
	};

	const removeDashboardFilterAsync = async (filter: DashboardFilter, save?: boolean) => {
		!unSavedChanges && setUnSavedChanges(true);

		if (filter.id && !filter.isNew && save) {
			try {
				await DashboardFilter.Delete(filter.id);
				setDashboardFilters(
					(filters) => filters && [...filters.filter((f) => f.id != filter.id)]
				);
			} catch (error) {
				addToast('Error deleting filter.', AlertVariant.danger);
			}
		} else {
			setDashboardFiltersToDelete((filters) => filters && [...filters, filter]);
			setDashboardFilters(
				(filters) => filters && [...filters.filter((f) => f.id != filter.id)]
			);
		}
	};

	const handleDashboardSave: (isDashboardSaveAndNavigate?: boolean) => void = (
		isDashboardSaveAndNavigate?: boolean
	) => {
		if (validated == 'error') {
			return;
		}

		setUnSavedChanges(false);
		setLoading(true);

		handleDashboardSaveAsync()
			.then(() => {
				setLoading(false);

				if (!isDashboardSaveAndNavigate) {
					navigateToPage();
				}
			})
			.catch(() => {
				addToast('Dashboard save failed.', AlertVariant.danger);
			});
	};

	const handleDashboardSaveAsync = async () => {
		dashboardModel.hasBorders = gridBorder ?? false;
		dashboardModel.hasLayout = gridLayout ?? false;

		if (isCopy) {
			dashboardModel.id = undefined;
			dashboardModel.uuid = undefined;
		}

		if (!isCopy) {
			//Delete dashboard filters
			if (dashboardFiltersToDelete?.length) {
				await Promise.all(
					dashboardFiltersToDelete.map(async (filter) => {
						await removeDashboardFilterAsync(filter, true);
					})
				);

				//Clear dashboardFiltersToDelete list state variable else the next time you save the dashboard it will try and delete the filters again
				setDashboardFiltersToDelete([]);
			}

			//Delete widgets
			if (widgetsToDelete?.length) {
				await Promise.all(
					widgetsToDelete.map(async (widget) => {
						await removeWidgetAsync(widget, true);
					})
				);

				//Clear widgetsToDelete list state variable else the next time you save the dashboard it will try and delete the widgets again
				setWidgetsToDelete([]);
			}
		}

		let newDashboardId = 0;

		if (!dashboardModel.id) {
			try {
				const dashboardResponse = await Dashboard.New(dashboardModel);
				setDashboardModel({ ...dashboardModel, ...dashboardResponse });
				newDashboardId = dashboardResponse.id ?? 0;
			} catch (error) {
				addToast('Dashboard create failed.', AlertVariant.danger);
			}

			//Save dashboard filters
			if (dashboardFilters?.length) {
				await Promise.all([
					dashboardFilters.map(async (filter) => {
						if (isCopy) {
							filter.dashboard = newDashboardId;
						}
						await handleDashboardFilterSave(filter);
					}),
				]);
			}

			//Save widgets
			if (widgets?.length) {
				await Promise.all(
					widgets.map(async (widget) => {
						if (isCopy) {
							widget.dashboard = newDashboardId;
							widget.isNew = true;
						}
						await handleDashboardWidgetSave(widget);
					})
				);
			}

			if (isCopy) {
				navigate(`/analyze/dashboards/${newDashboardId}`);
			}
		} else {
			try {
				const dashboardResponse = await Dashboard.Edit(dashboardModel);
				setDashboardModel({ ...dashboardModel, ...dashboardResponse });
				addToast('Dashboard updated successfully.', AlertVariant.success);
			} catch (error) {
				addToast('Dashboard update failed.', AlertVariant.danger);
			}

			//Save dashboard filters
			if (dashboardFilters?.length) {
				await Promise.all([
					dashboardFilters.map(async (filter) => {
						await handleDashboardFilterSave(filter);
					}),
				]);
			}

			//Save widgets
			if (widgets?.length) {
				await Promise.all(
					widgets.map(async (widget) => {
						await handleDashboardWidgetSave(widget);
					})
				);
			}
		}
	};

	const handleDashboardWidgetChange = (widget: DashboardWidget) => {
		!unSavedChanges && setUnSavedChanges(true);
		setWidget(widget);

		if (widget.isNew && !widget.widgetChanged) {
			setWidgets((widgets) => {
				return [...widgets, widget as TDashboardWidget];
			});
		} else {
			setWidgets((widgets) => {
				const newWidgets = [...widgets];
				newWidgets[widgets.findIndex((w) => w.id === widget.id)] = {
					...widget,
				};
				return newWidgets;
			});
		}
	};

	const handleDashboardWidgetSave = async (widget: DashboardWidget) => {
		if (!isCopy) {
			widget.dashboard = dashboardModel.id ?? 0;
		}

		if (widget.isNew) {
			widget.id = undefined;

			try {
				const widgetResponse = await DashboardWidget.New(widget);
				setWidgets((widgets) => {
					return [...widgets.filter((w) => w.id !== undefined), widgetResponse];
				});
			} catch (error) {
				addToast('Widget add failed.', AlertVariant.danger);
			}
		} else if (widget.id) {
			try {
				await DashboardWidget.Edit(widget);
			} catch (error) {
				addToast('Widget update failed.', AlertVariant.danger);
			}
		}
	};

	const handleDashboardFilterSave = async (dashboardFilter: DashboardFilter) => {
		if (!isCopy) {
			dashboardFilter.dashboard = dashboardModel.id ?? 0;
		}

		if (dashboardFilter.isNew) {
			dashboardFilter.id = undefined;

			try {
				const filterResponse = await DashboardFilter.New(dashboardFilter);
				setDashboardFilters((dashboardFilters) => {
					return (
						dashboardFilters && [
							...dashboardFilters.filter((d) => d.id !== undefined),
							filterResponse,
						]
					);
				});
			} catch (error) {
				addToast('Error adding filter.', AlertVariant.danger);
			}
		} else if (dashboardFilter.id) {
			try {
				const filterResponse = await DashboardFilter.Edit(dashboardFilter);
				setDashboardFilters((dashboardFilters) => {
					const newFilters = dashboardFilters && [...dashboardFilters];

					if (dashboardFilters && newFilters) {
						newFilters[
							dashboardFilters.findIndex(
								(d) => !d.isNew && d.id === filterResponse.id
							)
						] = {
							...filterResponse,
						};
					}

					return newFilters;
				});
			} catch (error) {
				addToast('Error updating filter.', AlertVariant.danger);
			}
		}
	};

	//When editing a chart, set the widget for the EditChartModal
	const handleEditClick: (widget: DashboardWidget) => void = (widget: DashboardWidget) => {
		widget.isEdit = true;
		setWidget(widget);

		if (unSavedChanges) {
			setIsEditModalOpen(true);
			setUnSavedChanges(false);
		} else {
			navigateToPage();
		}
	};

	const handleDashboardModalToggle = () => {
		setIsModalOpen((prev) => !prev);
	};

	const navigateToPage = () => {
		if (dashboardModel.id && widget && (widget.chart || widget.report) && widget.isEdit) {
			const snippet = widget.chart
				? `chart/${widget.chart}`
				: widget.report
				? `table/edit/${widget.report}`
				: '';
			navigate(`/analyze/dashboards/${dashboardModel.id}/${snippet}`, {
				replace: true,
			});
		} else {
			blocker?.proceed && blocker?.proceed();
		}
	};

	const optionsModalProps: ZiChartOptionsModalProps = {
		isOpen: isModalOpen,
		onClose: handleDashboardModalToggle,
		dashboardId: dashboardModel.id,
	};

	if (loading) {
		return <Loader />;
	}

	return (
		<>
			<Card
				className={'dashboard-details-container'}
				style={{ marginBottom: '0.24rem' }}
			>
				{isCopy && (
					<>
						<div className="pf-c-alert pf-m-inline pf-m-info">
							<p className="pf-c-alert__title display-inline">
								<FontAwesomeIcon
									size="2x"
									icon={faInfoCircle}
									className="bolder"
								/>
								<span className="nudge-right"></span>
								<div className="fs-large">
									{`Note! You are currently creating a copy of the dashboard '${
										existingDashboardName ?? ''
									}'`}
								</div>
							</p>
						</div>
						<br />
					</>
				)}
				<CardBody style={{ paddingBottom: '1.5rem' }}>
					<Grid
						className="row-container"
						span={12}
						style={{ paddingBottom: '0rem' }}
					>
						<div className="start-container">
							<FormGroup
								label={'Dashboard Name'}
								type="text"
								isRequired
								fieldId="name"
								helperTextInvalid={'Dashboard Name is required'}
								helperTextInvalidIcon={<ExclamationCircleIcon />}
								validated={validated}
							>
								<TextInput
									isRequired
									type="text"
									aria-label={'Enter a Dashboard name'}
									placeholder={'Enter a Dashboard name'}
									value={!isCopy ? dashboardModel.name : undefined}
									onChange={handleNameChange}
								/>
							</FormGroup>
							<SelectFolderDropdown
								folderId={dashboardModel.folder as number}
								folders={folders}
								onFolderSelect={(e, item) => {
									setDashboardModel({
										...dashboardModel,
										folder: parseInt(item.id ?? '0'),
									});
								}}
							/>
						</div>

						<GridItem span={4}>
							<Flex justifyContent={{ default: 'justifyContentFlexEnd' }}>
								<FlexItem>
									<Button onClick={() => handleDashboardSave(true)}>Save</Button>
								</FlexItem>
								<FlexItem>
									<Button
										variant="secondary"
										onClick={() =>
											navigate(
												`/analyze/dashboards/view/${dashboardModel.id ?? 0}`
											)
										}
									>
										Return
									</Button>
								</FlexItem>
							</Flex>
						</GridItem>
					</Grid>
					<DashboardFilters
						dashboardId={dashboardModel.id ?? 0}
						setFilters={setDashboardFilters}
						setSelectedDate={updateSelectedDate}
						setUnSavedChanges={setUnSavedChanges}
						setDashboardFiltersToDelete={setDashboardFiltersToDelete}
					/>
				</CardBody>
			</Card>
			<Card>
				<CardBody>
					<Grid hasGutter>
						<GridItem span={12}>
							{!dashboardFilters ? (
								<Loader />
							) : (
								<DropGrid
									widgets={widgets}
									handleWidgetChange={handleDashboardWidgetChange}
									removeWidget={removeWidget}
									handleEditClick={handleEditClick}
									isDashboard
									isEdit
									gridLayout={gridLayout}
									gridBorder={dashboardModel.hasBorders ?? false}
									filters={dashboardFilters}
									selectedDate={selectedDate}
								/>
							)}
						</GridItem>
					</Grid>
				</CardBody>
			</Card>
			<EditChartModal
				isOpen={isEditModalOpen}
				onClose={navigateToPage}
				onSubmit={() => handleDashboardSave(false)}
				titleText="Save Dashboard Confirmation"
				textLabel="Would you like to save your changes before proceeding? Clicking No will discard all changes."
				cancelLabel="No"
				confirmLabel="Yes"
			/>
			<ZiChartOptionsModal {...optionsModalProps} />
		</>
	);
};

export default DashboardDetails;
