import React, { useCallback, useState } from 'react';
import { useMount } from 'react-use';
import './DashboardFilters.scss';
import { TDimension } from '../../../api/analytics/Dimension';
import AddFilterModal from './AddFilterModal';
import { DashboardFilter, TDashboardFilter } from '../../../api/dashbboards/DashboardFilter';
import { AlertVariant, Button, Form, FormGroup, Tooltip } from '@patternfly/react-core';
import DatePeriodSelector from '../../../components/date-period-selector/DatePeriodSelector';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-light-svg-icons';
import Loader from '../../../components/util/Loader';
import EditFilterModal, { EditDashboardFilter } from './EditFilterModal';
import { TNewDateRange } from '../../../api/types/TNewDateRange';
import DashboardFilterDropdown from './DashboardFilter';
import { useToast } from '@zeroedin-tech/zi-common-ui/lib';
import { OptionsBuilderItemTypes } from '../../../types/dataframes/options-builder-item-types';
import { useApplication } from '../../../components/user/ApplicationProvider';
import { faFilter } from '@fortawesome/pro-solid-svg-icons';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';

type Props = {
	dashboardId: number;
	setFilters: React.Dispatch<React.SetStateAction<DashboardFilter[] | undefined>>;
	setSelectedDate: (startDateRange: TNewDateRange, endDateRange: TNewDateRange) => void;
	setUnSavedChanges?: React.Dispatch<React.SetStateAction<boolean>>;
	setDashboardFiltersToDelete?: React.Dispatch<
		React.SetStateAction<DashboardFilter[] | undefined>
	>;
	isView?: boolean;
};

const DashboardFilters = (props: Props) => {
	const {
		dashboardId,
		setFilters,
		setSelectedDate,
		setUnSavedChanges,
		setDashboardFiltersToDelete,
		isView,
	} = props;
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const { dimensions } = useApplication();
	const [allSeries, setAllSeries] = useState<TDimension[]>(dimensions);
	const [isAddFilterModalOpen, setIsAddFilterModalOpen] = useState<boolean>(false);
	const [isEditFilterModalOpen, setIsEditFilterModalOpen] = useState<boolean>(false);
	const [dashboardFilters, setDashboardFilters] = useState<DashboardFilter[]>([]);
	const [currentEditDashboardFilter, setCurrentEditDashboardFilter] =
		useState<EditDashboardFilter>({
			name: '',
			filter: DashboardFilter.Default(),
		});
	const [viewOnlyFilters, setViewOnlyFilters] = useState<number[]>([]);
	const { addToast } = useToast();

	useMount(() => {
		setIsLoading(true);

		DashboardFilter.GetAll({ dashboard: dashboardId.toString() })
			.then((dashboardFilters) => {
				setDashboardFilters(dashboardFilters);
				setFilters(dashboardFilters);
				if (isView) {
					setViewOnlyFilters(
						dashboardFilters
							.filter((df) => df.value === undefined || df.value === '')
							.map((df) => df.id ?? 0)
					);
				}
			})
			.catch((): void => {
				addToast('Error loading filters.', AlertVariant.danger);
			})
			.finally(() => {
				setIsLoading(false);
			});

		const mappedDimensions = allSeries.map((x) => ({
			...x,
			type: OptionsBuilderItemTypes.Dimension,
			dimensionAttributes: x.dimensionAttributes.map((y) => ({
				...y,
				type: OptionsBuilderItemTypes.DimensionAttribute,
			})),
		}));

		setAllSeries(mappedDimensions);
	});

	const handleFilterModalOpen = (dashboardFilterId: number, entityId: number) => {
		let name = '';
		const current = dashboardFilters.find((df) => df.id === dashboardFilterId);

		if (current) {
			if (current.entity_type === OptionsBuilderItemTypes.Dimension) {
				const dim = allSeries.find((s) => s.id === entityId);
				name = dim?.name ?? '';
			} else if (current.entity_type === OptionsBuilderItemTypes.DimensionAttribute) {
				const dim = allSeries.find((s) =>
					s.dimensionAttributes.some(
						(attr) => attr.id === entityId && attr.type === current.entity_type
					)
				);
				const dimAttr = dim?.dimensionAttributes.find(
					(attr) => attr.id === entityId && attr.type === current.entity_type
				);
				name = dimAttr?.name ?? '';
			}

			setCurrentEditDashboardFilter({
				name: name,
				filter: current,
			});
			setIsEditFilterModalOpen(true);
		}
	};

	const allowEdit = (dashboardFilterId: number) => {
		const current = dashboardFilters.find((df) => df.id === dashboardFilterId);
		return !isView || (isView && (current?.value === undefined || current?.value === ''));
	};

	const getDashboardFilter = (dashboardFilter: DashboardFilter) => {
		let name = '';
		const current = dashboardFilters.find((df) => df.id === dashboardFilter.id);

		if (dashboardFilter.entity_type === OptionsBuilderItemTypes.Dimension) {
			const dim = allSeries.find((s) => s.id === dashboardFilter.entity_id);
			name = dim?.name ?? '';
		} else if (dashboardFilter.entity_type === OptionsBuilderItemTypes.DimensionAttribute) {
			const dim = allSeries.find((s) =>
				s.dimensionAttributes.some(
					(attr) =>
						attr.id === dashboardFilter.entity_id &&
						attr.type === dashboardFilter.entity_type
				)
			);

			const dimAttr = dim?.dimensionAttributes.find(
				(attr) =>
					attr.id === dashboardFilter.entity_id &&
					attr.type === dashboardFilter.entity_type
			);
			name = dimAttr?.name ?? '';
		}

		return (
			<DashboardFilterDropdown
				id={dashboardFilter.id ?? 0}
				entityId={dashboardFilter.entity_id}
				handleFilterModalOpen={handleFilterModalOpen}
				handleRemoveDashboardFilter={handleRemoveDashboardFilter}
				name={name}
				isView={isView}
				editable={allowEdit(dashboardFilter.id ?? 0)}
				isFiltered={current?.value && current.value !== '' ? true : false}
			/>
		);
	};

	const handleSaveDashboardFilter = useCallback((dashboardFilter: TDashboardFilter) => {
		if (!isView && allowEdit(dashboardFilter.id ?? 0)) {
			setUnSavedChanges && setUnSavedChanges(true);

			setDashboardFilters((dashboardFilters) => {
				const index = dashboardFilters.findIndex((df) => df.id === dashboardFilter.id);
				dashboardFilters[index] = dashboardFilter;
				setFilters([...dashboardFilters].sort((a, b) => a.sequence - b.sequence));
				return [...dashboardFilters].sort((a, b) => a.sequence - b.sequence);
			});
		} else {
			setDashboardFilters((dashboardFilters) => {
				const index = dashboardFilters.findIndex((df) => df.id === dashboardFilter.id);
				dashboardFilters[index] = dashboardFilter;
				setFilters([...dashboardFilters].sort((a, b) => a.sequence - b.sequence));
				return [...dashboardFilters].sort((a, b) => a.sequence - b.sequence);
			});
		}
	}, []);

	const handleAddDashboardFilter = (selectedDimId: number, entityType: string) => {
		setUnSavedChanges && setUnSavedChanges(true);

		const dashboardFilterId = dashboardFilters.find(
			(d) => d.id === dashboardFilters.length + 1
		)?.id;

		const newModel = DashboardFilter.Default();
		newModel.dashboard = dashboardId;
		newModel.id = dashboardFilterId ? dashboardFilterId + 1 : dashboardFilters.length + 1; //A temp id assigned to a filter that will not be saved to the DB
		newModel.entity_id = selectedDimId;
		newModel.entity_type = entityType;
		newModel.isNew = true;

		setDashboardFilters((dashboardFilters) => {
			setFilters([...dashboardFilters, newModel].sort((a, b) => a.sequence - b.sequence));
			return [...dashboardFilters, newModel].sort((a, b) => a.sequence - b.sequence);
		});
	};

	const handleRemoveDashboardFilter = (id: number) => {
		setUnSavedChanges && setUnSavedChanges(true);

		setDashboardFiltersToDelete &&
			setDashboardFiltersToDelete(
				() => dashboardFilters && [...dashboardFilters].filter((df) => df.id === id)
			);

		setDashboardFilters((dashboardFilters) => {
			setFilters(
				[...dashboardFilters]
					.filter((df) => df.id !== id)
					.sort((a, b) => a.sequence - b.sequence)
			);
			return [...dashboardFilters]
				.filter((df) => df.id !== id)
				.sort((a, b) => a.sequence - b.sequence);
		});
	};

	const handleRemoveAllDashboardFilters = () => {
		setUnSavedChanges && setUnSavedChanges(true);

		setDashboardFilters((dashboardFilters) => {
			dashboardFilters.forEach((filter) => {
				const readonly = isView && !viewOnlyFilters.find((i) => i === filter.id);
				filter.value = readonly ? filter.value : '';
			});

			setFilters([...dashboardFilters].sort((a, b) => a.sequence - b.sequence));
			return [...dashboardFilters].sort((a, b) => a.sequence - b.sequence);
		});
	};

	return (
		<>
			<Form
				isHorizontal
				className="dashboard-form"
			>
				<FormGroup
					label={
						<div className="formgroup-label">
							Filters
							<FontAwesomeIcon icon={faFilter} />
							<div
								className="remove-btn"
								style={{
									marginLeft: '-15px',
								}}
							>
								<Tooltip content="Clear All Filters">
									<Button
										variant="plain"
										alt="Clear All Filters"
										icon={
											<FontAwesomeIcon
												icon={faTimes}
												onClick={() => handleRemoveAllDashboardFilters()}
											/>
										}
									></Button>
								</Tooltip>
							</div>
						</div>
					}
					isInline
				>
					<div className="filter-container">
						<div className="date-selector-container">
							<DatePeriodSelector applyDateRange={setSelectedDate} />
						</div>
						{isLoading ? (
							<div className="filter">
								<Loader size="md" />
							</div>
						) : (
							dashboardFilters.map((df) => {
								return getDashboardFilter(df);
							})
						)}
						{!isView && (
							<div className="add-filter">
								<Tooltip content="Add Filter">
									<Button
										className="btn-add-filter"
										alt="Add Filter"
										icon={<FontAwesomeIcon icon={faPlus} />}
										onClick={() => {
											setIsAddFilterModalOpen(true);
										}}
									>
										<strong>Add Filter</strong>
									</Button>
								</Tooltip>
							</div>
						)}
					</div>
				</FormGroup>
			</Form>
			{isAddFilterModalOpen && (
				<AddFilterModal
					setModalOpen={setIsAddFilterModalOpen}
					isOpen={isAddFilterModalOpen}
					addDashboardFilter={handleAddDashboardFilter}
					selectedIds={dashboardFilters.map((f) => f.entity_id)}
				/>
			)}

			<EditFilterModal
				dashboardFilter={currentEditDashboardFilter}
				setModalOpen={setIsEditFilterModalOpen}
				isOpen={isEditFilterModalOpen}
				saveDashboardFilter={handleSaveDashboardFilter}
				readonly={
					!viewOnlyFilters.find((i) => i === currentEditDashboardFilter.filter.id) &&
					!allowEdit(currentEditDashboardFilter.filter.id ?? 0)
				}
			/>
		</>
	);
};

export default DashboardFilters;
