import {
	AlertVariant,
	Button,
	Card,
	CardBody,
	Flex,
	FormGroup,
	Grid,
	GridItem,
	TextInput,
} from '@patternfly/react-core';
import React, { useCallback, useState } from 'react';
import PresentationPageList from './PresentationPageList';
import {
	PresentationDetail as PresentationDetailType,
	PresentionationContext,
	PresentationSlide as PresentationSlideType,
} from '../../types/presentation';
import { useOutletContext } from 'react-router';
import { GridLayoutOutletContext } from '../../layout/Layout';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { DashboardWidget } from '../../api/dashbboards/DashboardWidgets';
import PresentationSlide from './PresentationSlide';
import { useMount } from 'react-use';
import { Folder, TFolder } from '../../api/foundational-elements/Folder';
import { useToast } from '@zeroedin-tech/zi-common-ui/lib';
import ExclamationCircleIcon from '@patternfly/react-icons/dist/esm/icons/exclamation-circle-icon';
import SelectFolderDropdown from '../../helpers/helper-components/SelectFolderDropdown';

interface Props {
	pageContext: PresentionationContext;
}

export type PresentationWidget = DashboardWidget;

const PresentationDetail = (props: Props) => {
	const { pageContext } = props;
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const presentationId = searchParams.get('presentationId');
	const { addToast } = useToast();
	const { gridLayout } = useOutletContext<GridLayoutOutletContext>();
	const [selectedSlide, setSelectedSlide] = useState<number>(0);
	let presentationModel: PresentationDetailType = { id: 0, name: '', slides: [{ widgets: [] }] };
	if (presentationId) {
		presentationModel =
			pageContext.presentations.find((p) => p.id === parseInt(presentationId)) ??
			presentationModel;
	}
	const [currentSlide, setCurrentSlide] = useState<PresentationSlideType>(
		presentationModel.slides[selectedSlide]
	);
	const [pageModel, setPageModel] = useState<PresentationDetailType>(presentationModel);
	const [folders, setFolders] = useState<TFolder[]>([]);

	useMount(() => {
		Folder.GetAll({ type: 'presentations' })
			.then((response) => {
				if (response) {
					setFolders(response.filter((f) => f.type === 'presentations'));
				}
			})
			.catch(() => {
				addToast('Get folders failed.', AlertVariant.danger);
			});
	});

	const updateModel = useCallback(
		(widgets: PresentationWidget[]) => {
			pageModel.slides[selectedSlide].widgets = widgets;
			setPageModel(pageModel);
		},
		[pageModel, setPageModel, selectedSlide]
	);

	const addNewPage = useCallback(() => {
		pageModel.slides.push({ widgets: [] });
		setPageModel(pageModel);
		setSelectedSlide(pageModel.slides.length - 1);
		setCurrentSlide({ ...pageModel.slides[pageModel.slides.length - 1] });
	}, [pageModel, setPageModel, selectedSlide, setSelectedSlide]);

	const removePage = useCallback(
		(index: number) => {
			pageModel.slides.splice(index, 1);
			if (selectedSlide === index && index > 1) {
				setSelectedSlide(index - 1);
				setCurrentSlide({ ...pageModel.slides[index - 1] });
			} else {
				setSelectedSlide(0);
				setCurrentSlide({ ...pageModel.slides[0] });
			}
			setPageModel(pageModel);
		},
		[pageModel, setPageModel, selectedSlide, setSelectedSlide]
	);

	const updateSelectedPage = useCallback(
		(slideNumber: number) => {
			setSelectedSlide(slideNumber);
			setCurrentSlide({ ...pageModel.slides[slideNumber] });
		},
		[pageModel, setPageModel, selectedSlide, setSelectedSlide]
	);

	const handlePresentationWidgetSave = useCallback(
		(widget: PresentationWidget) => {
			const newPageModel = { ...pageModel };
			if (widget.id !== 0 && !widget.id) {
				widget.id = newPageModel.slides[selectedSlide].widgets.length + 1;
				newPageModel.slides[selectedSlide].widgets = [
					...(newPageModel.slides[selectedSlide].widgets as PresentationWidget[]),
					widget,
				];
			} else {
				const newWidgets = [
					...(newPageModel.slides[selectedSlide].widgets as PresentationWidget[]),
				];

				newWidgets[
					newPageModel.slides[selectedSlide].widgets.findIndex(
						(w: PresentationWidget) => w.id === widget.id
					)
				] = {
					...widget,
				};
				newPageModel.slides[selectedSlide].widgets = newWidgets;
			}
			updateModel(newPageModel.slides[selectedSlide].widgets as PresentationWidget[]);
			setCurrentSlide({ ...newPageModel.slides[selectedSlide] });
		},
		[pageModel, selectedSlide]
	);

	const handleChartEditClick = useCallback(
		(widget: PresentationWidget) => {
			if (widget.chart) {
				// presentationModel.id &&
				navigate(`/present/${presentationModel.id}/chart/${widget.chart}`);
			}
		},
		[presentationModel]
	);

	const removeWidget = useCallback((widget: PresentationWidget) => {
		if (widget.id) {
			pageModel.slides[selectedSlide].widgets = [
				...(pageModel.slides[selectedSlide].widgets.filter(
					(w: PresentationWidget) => w.id != widget.id
				) as PresentationWidget[]),
			];
		} else {
			pageModel.slides[selectedSlide].widgets = [
				...(pageModel.slides[selectedSlide].widgets.filter(
					(w: PresentationWidget) => w.id != widget.id
				) as PresentationWidget[]),
			];
		}
		updateModel(pageModel.slides[selectedSlide].widgets as PresentationWidget[]);
		setCurrentSlide({ ...pageModel.slides[selectedSlide] });
	}, []);

	const handleNameChange = (value: string, _event: React.FormEvent<HTMLInputElement>) => {
		setPageModel({ ...pageModel, name: value });
	};

	return (
		<>
			<Card
				className={'present-details-container'}
				style={{ marginBottom: '0.24rem' }}
			>
				<CardBody style={{ paddingBottom: '1.5rem' }}>
					<Grid
						className="row-container"
						span={12}
					>
						<div className="start-container">
							<FormGroup
								label={'Presentation Name'}
								type="text"
								isRequired
								fieldId="name"
								helperTextInvalid={'Presentation Name is required'}
								helperTextInvalidIcon={<ExclamationCircleIcon />}
							>
								<TextInput
									isRequired
									type="text"
									aria-label={'Enter a Presentation Name'}
									placeholder={'Enter a Presentation Name'}
									value={pageModel.name}
									onChange={handleNameChange}
								/>
							</FormGroup>
							<SelectFolderDropdown
								folderId={pageModel.folder as number}
								folders={folders}
								onFolderSelect={(e, item) => {
									setPageModel({
										...pageModel,
										folder: parseInt(item.id ?? '0'),
									});
								}}
							/>
						</div>
						<GridItem span={6}>
							<Flex justifyContent={{ default: 'justifyContentFlexEnd' }}>
								<Button>Save Presentation</Button>
							</Flex>
						</GridItem>
					</Grid>
				</CardBody>
			</Card>
			<Card>
				<CardBody>
					<Grid hasGutter>
						<GridItem span={2}>
							<PresentationPageList
								slides={pageModel.slides}
								selectedPage={selectedSlide}
								addNewPage={addNewPage}
								removePage={removePage}
								updateSelectedPage={updateSelectedPage}
							/>
						</GridItem>
						<GridItem span={10}>
							<PresentationSlide
								slide={currentSlide}
								handlePresentationWidgetSave={handlePresentationWidgetSave}
								removeWidget={removeWidget}
								handleEditClick={handleChartEditClick}
								gridLayout={gridLayout}
							/>
						</GridItem>
					</Grid>
				</CardBody>
			</Card>
		</>
	);
};

export default PresentationDetail;
