export enum AddNewDropdownTypes {
	Chart = 'chart',
	Dashboard = 'dashboard',
	Dataframe = 'dataframe',
	CopyDataframe = 'copyDataframe',
	Folder = 'folder',
	Report = 'report',
	Schedule = 'schedule',
	Table = 'table',
}
