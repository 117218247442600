import { useState, useEffect } from 'react';
import { useLocation, useOutletContext } from 'react-router';
import { OutletContext } from '../../../layout/Layout';
import { useToast } from '@zeroedin-tech/zi-common-ui/lib';
import { OptionsBuilderItemTypes } from '../../../types/dataframes/options-builder-item-types';
import { Dimension } from '../../../api/analytics/Dimension';
import { Folder, TFolder } from '../../../api/foundational-elements/Folder';
import { FolderTypesEnum } from '../../../enums/folder-types-enum';
import { AlertVariant, Card, CardBody } from '@patternfly/react-core';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import {
	DataframeDataRetrievalRequest,
	DataframeDataRetrievalResponse,
	TDataframe,
	TDataframeOrder,
	TDateRange,
	TReport,
	TReportConditionalRules,
} from '../../../api/types';
import { Dataframe, TNewDataframe, TNewDataframeOrder } from '../../../api/dataframes/Dataframes';
import { Report } from '../../../api/reports/Reports';
import Loader from '../../../components/util/Loader';
import { isEqual } from 'lodash';
import { DataBuilderPropsV2, DraggableMenuItemData } from '../../../types/databuilder/databuilder';
import { TNewDateRange } from '../../../api/types/TNewDateRange';
import ZiDataBuilderV2 from '../../../components/data-builder/ZiDataBuilderV2';
import { DataBuilderTypes } from '../../../enums/data-builder-types';
import './BuildReport.scss';
import {
	buildDataframeRequest,
	populateDroppedColumnsByDataframe,
	populateDroppedFactsByDataframe,
	populateDroppedFactsByKeyMeasure,
	populateDroppedFactsByKeyMeasureFact,
	populateDroppedFilterByDimension,
	populateDroppedFilterByDimensionAttribute,
	populateDroppedFiltersByDataframe,
	populateDroppedRowsByDataframe,
	populateDroppedRowsByKeyMeasure,
	populateDroppedRowsByKeyMeasureFact,
} from '../../../hooks/DataBuilderHooks';
import { useApplication, useUser } from '../../../components/user/ApplicationProvider';
import { MultipartResponse } from '../../../helpers/multipart-response.helper';
import Preview, { IPreviewProps } from '../../../components/data-builder/Preview';
import { selectedPeriod } from '../../../helpers/selected-period.helper';
import { DateRange } from '../../../api/date-period-selector/DateRange';
import {
	addNewRecentReport,
	addNewRecentTable,
} from '../../../helpers/helper-components/recents-factory-helper';
import { TSharedEntity } from '../../../api/shared-entity/SharedEntity';
import ViewReport from './ViewReport';
import { Favorites, TFavorites } from '../../../api/favorites/Favorites';
import { FavoriteTypes } from '../../../enums/favorite-types';
import { DashboardWidget } from '../../../api/dashbboards/DashboardWidgets';
import { FilterOperatorEnum } from '../../../enums/operators.enum';
import { DimensionAttribute } from '../../../api/analytics/DimensionAttribute';
import { DatePeriodReformatter } from '../../../components/date-period-selector/DatePeriodFormatter';
import { ConditionalRule } from '../../../types/conditional-rules/conditional-rules';
import { SortTypes } from '../../../types/common/sort-types';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons';

type Props = {
	type: DataBuilderTypes;
};

const BuildReport = (props: Props) => {
	const { reportId, tableId, dashboardId, presentationId } = useParams();
	const navigator = useNavigate();
	const { currentDatePeriods, measures, dimensions, unitTypes, periods } = useApplication();
	const currentPeriod = selectedPeriod();
	const defaultPeriod =
		currentDatePeriods.find((dp) => dp.period === 3) ?? (DateRange.Default() as TDateRange);
	const [searchParams, _setSearhcParams] = useSearchParams();
	const queryParams = {
		reportId: searchParams.get('reportId'),
		tableId: searchParams.get('tableId'),
		dataframeId: searchParams.get('dataframeId'),
		measureId: searchParams.get('measureId'),
		measureFactId: searchParams.get('measureFactId'),
		dimensionAttributeId: searchParams.get('dimensionAttributeId'),
		dimensionId: searchParams.get('dimensionId'),
	};
	const { setSubSide } = useOutletContext<OutletContext>();
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const { addToast } = useToast();
	const [folders, setFolders] = useState<TFolder[]>([]);
	const [dataframe, setDataframe] = useState<TDataframe>();
	const [parentDataframe, setParentDataframe] = useState<TDataframe>();
	const [report, setReport] = useState<TReport>();
	const [name, setName] = useState<string>();
	const [folder, setFolder] = useState<TFolder>();
	const [selectedDate, setSelectedDate] = useState<TNewDateRange | undefined>({
		begin_date: currentPeriod.startPeriod
			? currentPeriod.startPeriod.begin_date
			: defaultPeriod.begin_date ?? 0,
		end_date: currentPeriod.endPeriod
			? currentPeriod.endPeriod.end_date
			: defaultPeriod.end_date ?? 0,
		period: currentPeriod.startPeriod
			? currentPeriod.startPeriod.period
			: defaultPeriod.period ?? 0,
		sequence: currentPeriod.startPeriod
			? currentPeriod.startPeriod.period
			: defaultPeriod.sequence ?? 0,
	});
	const [facts, setFacts] = useState<DraggableMenuItemData[]>([]);
	const [rows, setRows] = useState<DraggableMenuItemData[]>([]);
	const [columns, setColumns] = useState<DraggableMenuItemData[]>([]);
	const [filters, setFilters] = useState<DraggableMenuItemData[]>([]);
	const [parameters, setParameters] = useState<DraggableMenuItemData[]>([]);
	const [drillRows, setDrillRows] = useState<DraggableMenuItemData[]>([]);
	const [drillFilters, setDrillFilters] = useState<DraggableMenuItemData[]>([]);
	const [order, setOrder] = useState<(TDataframeOrder | TNewDataframeOrder)[]>([]);
	const [previewData, setPreviewData] =
		useState<MultipartResponse<DataframeDataRetrievalResponse>>();
	const [isPreviewLoading, setPreviewIsLoading] = useState<boolean>(false);
	const [showPreview, setShowPreview] = useState<boolean>(false);
	const [disablePreviewBtn, setDisablePreviewBtn] = useState<boolean>(true);
	const [isEdit, setIsEdit] = useState<boolean>(false);
	const [isReportView, setIsReportView] = useState<boolean>(false);
	const [isCreate, setIsCreate] = useState<boolean>(false);
	const [dataframeChanged, setDataframeChanged] = useState<boolean>(false);
	const [overrideRequest, setOverrideRequest] = useState<TDataframe | TNewDataframe>();
	const [favorite, setFavorite] = useState<TFavorites>();
	const currentUser = useUser();
	const [widgetRow, setWidgetRow] = useState<number>(0);
	const pathname = useLocation().pathname;
	const isView = pathname.includes('/view/');
	const [datePeriodSelectorOverride, setDatePeriodSelectorOverride] = useState<TDateRange>();
	const [datePeriodSelectorLabel, setDatePeriodSelectorLabel] = useState<string>();
	const [datePeriodSelectorDisabledMessage, setDatePeriodSelectorDisabledMessage] =
		useState<string>();
	const [conditionalFormattingRules, setConditionalFormattingRules] = useState<
		TReportConditionalRules[]
	>([]);
	const [prevRetrievalRequest, setPrevRetrievalRequest] =
		useState<DataframeDataRetrievalRequest>();
	const [hasReportLoaded, setHasReportLoaded] = useState<boolean>(false);
	const useLocationqueryParams = useLocation().search;
	const isCopy = useLocationqueryParams.includes('isCopy=true');

	// validation
	const [validated, setValidated] = useState<
		'default' | 'warning' | 'success' | 'error' | undefined
	>();

	useEffect(() => {
		setIsLoading(true);
		setSubSide({ isLoading: true });

		if (dashboardId) {
			DashboardWidget.GetAll({ dashboard: dashboardId })
				.then((widgetsResponse) => {
					let row = widgetsResponse.length;
					if (row > 0) {
						row = widgetsResponse[row - 1].sizey + 1;
					}
					setWidgetRow(row);
				})
				.catch(() => {
					console.log('failed widget getall');
				});
		}

		if (reportId || tableId) {
			if (isView && (reportId || tableId)) {
				setIsReportView(true);
				setSubSide({ hideLeftSideBar: true });
			} else {
				setIsEdit(true);
			}

			setValidated('success');

			if (reportId) addNewRecentReport(reportId);
			if (tableId) addNewRecentTable(tableId);
		} else {
			if (!reportId) {
				setIsCreate(true);
			}

			if (isCopy) {
				setValidated('error');
			} else {
				setValidated('default');
			}
		}

		getPageData();
	}, []);

	useEffect(() => {
		if (isView && (reportId || tableId)) {
			setIsReportView(true);
			setSubSide({ hideLeftSideBar: true });
			setIsEdit(false);
		} else {
			setIsEdit(true);
		}
	}, [window.location.pathname]);

	// Populate dropped items from dataframe
	useEffect(() => {
		if (dataframe) {
			populateDroppedItems();
			setOverrideRequest(
				buildDataframeRequest(
					facts,
					rows,
					columns,
					filters,
					'override',
					order,
					false,
					true,
					dataframe,
					folder
				)
			);
		}
	}, [dataframe]);

	useEffect(() => {
		if (dataframe) {
			setPreviewIsLoading(true);
			setShowPreview(true);
			checkDrillItems();
		}

		const handler = setTimeout(() => {
			if (!disablePreviewBtn) {
				onPreview();
			}
		}, 500);

		return () => {
			clearTimeout(handler);
		};
	}, [
		facts,
		rows,
		columns,
		filters,
		selectedDate,
		order,
		drillRows,
		drillFilters,
		datePeriodSelectorOverride,
		parameters,
	]);

	useEffect(() => {
		if (report && report.folder) {
			setFolder(folders.find((folder) => folder.id === report.folder));
		}
	}, [report]);

	const checkDrillItems = () => {
		if (props.type === DataBuilderTypes.table) {
			const drillableRows = rows.filter((row) => row.data?.drillable);
			if (drillableRows.length) {
				if (!drillRows.length && !drillFilters.length) {
					setDrillRows(drillableRows.length ? [drillableRows[0]] : []);
				} else {
					if (
						drillRows.some((row) => !rows.includes(row)) ||
						drillRows.some((row) => !drillableRows.includes(row))
					) {
						setDrillRows([drillableRows[0]]);
						setDrillFilters([]);
					} else {
						if (drillableRows[0].id != drillRows[0].id) {
							setDrillRows([drillableRows[0]]);
							setDrillFilters([]);
						}
					}
				}
			} else {
				if (drillRows.length) {
					setDrillRows([]);
					setDrillFilters([]);
				}
			}
		}
	};

	const getPageData = () => {
		Folder.GetAll()
			.then((folders) => {
				if (folders) {
					const folderType =
						props.type === DataBuilderTypes.report
							? FolderTypesEnum.Report
							: FolderTypesEnum.Tables;
					const filteredFolders = folders.filter((f) => f.type === folderType);
					setFolders(filteredFolders);
				} else {
					setFolders([
						{
							id: -1,
							name: 'No folders found',
							type: 'dataframes',
							items: [],
						},
					]);
				}

				if (queryParams.reportId || reportId || queryParams.tableId || tableId) {
					const id =
						props.type === DataBuilderTypes.report
							? +(reportId ?? queryParams.reportId ?? 0)
							: +(tableId ?? queryParams.tableId ?? 0);
					getReportData(id);
					getFavoriteData();
				} else if (queryParams.dataframeId) {
					getDataframeData();
				} else if (
					queryParams.measureId &&
					queryParams.measureFactId &&
					(queryParams.dimensionAttributeId || queryParams.dimensionId)
				) {
					populateDroppedItems();
					createParentAndChildDataframes();
				}
			})
			.catch(() => {
				addToast('There was an issue retrieving data', AlertVariant.danger);
			});
	};

	const getReportData = (id: number) => {
		Report.Get(id, ['sharedReport', 'conditionalRules'])
			.then((response) => {
				setReport(response);

				if (!isCopy) {
					setName(response.name);
				}
				setConditionalFormattingRules(response.conditionalRules);
				getDataframeData(response.dataframe);
			})
			.catch(() => {
				addToast('There was an issue retrieving the report', AlertVariant.danger);
				setIsLoading(false);
				setSubSide({ isLoading: false });
			});
	};

	const getFavoriteData = () => {
		const id = DataBuilderTypes.report
			? +(reportId ?? queryParams.reportId ?? 0)
			: +(tableId ?? queryParams.tableId ?? 0);
		Favorites.Get(id, currentUser.id)
			.then((response: TFavorites) => {
				if (response) {
					setFavorite(response);
				} else {
					setFavorite({
						user: currentUser.id,
						object_id: id,
						type: FavoriteTypes.report,
						name: report?.name ?? '',
					});
				}
			})
			.catch(() => {
				addToast(
					'There was an issue setting the report as a favorite',
					AlertVariant.danger
				);
			});
	};

	const createParentAndChildDataframes = () => {
		if (
			queryParams.measureId &&
			queryParams.measureFactId &&
			(queryParams.dimensionAttributeId || queryParams.dimensionId)
		) {
			const childFacts = populateDroppedFactsByKeyMeasure(
				+queryParams.measureId,
				+queryParams.measureFactId,
				measures,
				unitTypes
			);

			const parentFacts = populateDroppedFactsByKeyMeasureFact(
				+queryParams.measureFactId,
				measures,
				unitTypes
			);

			const childRows = populateDroppedRowsByKeyMeasure(
				dimensions,
				queryParams.dimensionId ? +queryParams.dimensionId : undefined,
				queryParams.dimensionAttributeId ? +queryParams.dimensionAttributeId : undefined
			);

			const parentRows = populateDroppedRowsByKeyMeasureFact(
				+queryParams.measureFactId,
				measures,
				dimensions
			);

			const childDataframeRequest: TNewDataframe | TDataframe = buildDataframeRequest(
				childFacts,
				childRows,
				[],
				[],
				'',
				[],
				false,
				false,
				undefined,
				undefined,
				true
			);

			const parentDataframeRequest: TNewDataframe | TDataframe = buildDataframeRequest(
				parentFacts,
				parentRows,
				[],
				[],
				'',
				[],
				false,
				false,
				undefined,
				undefined
			);

			Dataframe.NewDataframe(parentDataframeRequest as TNewDataframe)
				.then((parent: TDataframe) => {
					childDataframeRequest.parent = parent.id;
					Dataframe.NewDataframe(childDataframeRequest as TNewDataframe)
						.then((child: TDataframe) => {
							getDataframeData(child.id);
						})
						.catch((): void => {
							addToast('Error creating child dataframe.', AlertVariant.danger);
							setIsLoading(false);
							setSubSide({ isLoading: false });
						});
				})
				.catch((): void => {
					addToast('Error creating parent dataframe.', AlertVariant.danger);
					setIsLoading(false);
					setSubSide({ isLoading: false });
				});
		}
	};

	const getDataframeData = (dataframeId?: number) => {
		const id = dataframeId ?? queryParams.dataframeId;
		if (id) {
			Dataframe.Get(+id, ['datasets', 'filters', 'rowEntry', 'columnEntry', 'order'])
				.then((response) => {
					if (response.parent) {
						Dataframe.Get(response.parent, [
							'datasets',
							'filters',
							'rowEntry',
							'columnEntry',
							'order',
						])
							.then((parentResponse) => {
								setParentDataframe(parentResponse);
								setDataframe(response);
								if (response.order && response.order.length) {
									setOrder(response.order);
								} else {
									setOrder([
										{
											dataframe: response.id,
											direction: SortTypes.Asc,
											entity_id: response.rowEntry[0].entity_id,
											entity_type: response.rowEntry[0].entity_type,
											useSequence: false,
											pivotSort: '',
										},
									]);
								}
								setDisablePreviewBtn(false);
							})
							.catch(() => {
								addToast(
									'There was an issue retrieving the parent dataframe',
									AlertVariant.danger
								);
								setIsLoading(false);
								setSubSide({ isLoading: false });
							});
					} else {
						setDataframe(response);
						setOrder(response.order ? response.order : []);
						setDisablePreviewBtn(false);
					}
				})
				.catch(() => {
					addToast('There was an issue retrieving the dataframe', AlertVariant.danger);
					setIsLoading(false);
					setSubSide({ isLoading: false });
				});
		}
	};

	const saveDataframe = () => {
		const request = buildDataframeRequest(
			facts,
			rows,
			columns,
			filters,
			name ? name : '',
			order,
			(queryParams.reportId != void 0 || queryParams.dataframeId != void 0) &&
				!dataframe?.parent,
			queryParams.dataframeId ? false : true,
			dataframe,
			folder,
			true
		);

		setIsLoading(true);

		if (queryParams.dataframeId || isCopy) {
			Dataframe.NewDataframe(request as TNewDataframe)
				.then((_: TDataframe): void => {
					setDisablePreviewBtn(false);
					getDataframeData(_.id);
					saveReport(_.id);
				})
				.catch((): void => {
					addToast('Error creating dataframe.', AlertVariant.danger);
					setIsLoading(false);
				});
		} else {
			Dataframe.PatchDataframe(request as TNewDataframe)
				.then((_: TDataframe): void => {
					getDataframeData(_.id);
					saveReport(_.id);
				})
				.catch((): void => {
					addToast('Error updating dataframe.', AlertVariant.danger);
					setIsLoading(false);
				});
		}
	};

	const saveReport = (dataframeId: number) => {
		const request = {
			...(report && !isCopy && { id: report.id }),
			name: name ? name : '',
			description: '',
			folder: folder ? folder?.id : 0,
			dataframe: dataframeId,
			conditionalRules: conditionalFormattingRules,
			owner: currentUser.id,
			is_from_dataframe: report?.is_from_dataframe ?? queryParams.dataframeId ? true : false,
			is_table: props.type === DataBuilderTypes.table,
		};

		if (reportId || tableId) {
			if (tableId) {
				request.id = +tableId;
			}
			if (reportId) {
				request.id = +reportId;
			}

			Report.Patch(request)
				.then((_: TReport): void => {
					if (dashboardId) {
						navigator(`/analyze/dashboards/${dashboardId}`);
					} else if (presentationId) {
						navigator(`/present/blank`);
					} else {
						getReportData(_.id);
					}
				})
				.catch((): void => {
					addToast(
						`Error updating ${
							props.type === DataBuilderTypes.table ? 'table' : 'report'
						}.`,
						AlertVariant.danger
					);
					setIsLoading(false);
				});
		} else {
			Report.New(request)
				.then((_: TReport): void => {
					if (dashboardId) {
						DashboardWidget.New({
							...DashboardWidget.Default(),
							name: _.name,
							dashboard: parseInt(dashboardId),
							report: _.id,
							widget_type: 'pivot_table',
							col: 0,
							row: widgetRow,
							sizex: 6,
							sizey: 6,
						})
							.then((_createWidgetResponse) => {
								setIsLoading(false);
								navigator(`/analyze/dashboards/${dashboardId}`);
							})
							.catch(() => {
								setIsLoading(false);
								addToast('Create dashboard widget failed.', AlertVariant.danger);
							});
					} else if (presentationId) {
						navigator(`/present/blank`);
					} else {
						setIsLoading(false);
						setIsEdit(true);
						navigator(
							`/${props.type === DataBuilderTypes.table ? 'table' : 'report'}/edit/${
								_.id
							}`
						);
					}
				})
				.catch((): void => {
					addToast(
						`Error creating ${
							props.type === DataBuilderTypes.table ? 'table' : 'report'
						}.`,
						AlertVariant.danger
					);
					setIsLoading(false);
				});
		}
	};

	const populateDroppedItems = () => {
		const dateRangeSelector: DraggableMenuItemData = {
			id: '-1',
			entityType: OptionsBuilderItemTypes.DatePeriodSelector,
			allowedZones: [],
			data: null,
			entity: {},
			itemType: 'date',
			static: true,
		};

		if (dataframe) {
			setFacts(populateDroppedFactsByDataframe(dataframe, measures));
			setRows(populateDroppedRowsByDataframe(dataframe, dimensions));
			checkDrillItems();
			setColumns(populateDroppedColumnsByDataframe(dataframe, dimensions));
			// Ensure to add the date range selector by default
			setFilters([
				...[dateRangeSelector],
				...populateDroppedFiltersByDataframe(dataframe, dimensions),
			]);
			setParameters(populateDroppedFiltersByDataframe(dataframe, dimensions, !isEdit));
		} else if (
			queryParams.measureId &&
			queryParams.measureFactId &&
			(queryParams.dimensionAttributeId || queryParams.dimensionId)
		) {
			setFacts(
				populateDroppedFactsByKeyMeasure(
					+queryParams.measureId,
					+queryParams.measureFactId,
					measures,
					unitTypes
				)
			);
			setRows(
				populateDroppedRowsByKeyMeasure(
					dimensions,
					queryParams.dimensionId ? +queryParams.dimensionId : undefined,
					queryParams.dimensionAttributeId ? +queryParams.dimensionAttributeId : undefined
				)
			);
		}
	};

	const onSave = (): void => {
		if (dataframeChanged) {
			saveDataframe();
		} else {
			saveReport(dataframe?.id ?? 0);
		}
	};

	const onPreview = (): void => {
		setHasReportLoaded(false);
		if (dataframe) {
			setPreviewIsLoading(true);
			setShowPreview(true);

			const newOverrideRequest = buildDataframeRequest(
				facts,
				props.type === DataBuilderTypes.table
					? [...rows.filter((row) => !row.data?.drillable), ...drillRows]
					: rows,
				columns,
				isView
					? [...parameters.filter((param) => param.data?.value), ...drillFilters]
					: props.type === DataBuilderTypes.table
					? [...filters.filter((filter) => filter.data?.value), ...drillFilters]
					: filters.filter((filter) => filter.data?.value),
				'override',
				order,
				false,
				true,
				dataframe,
				folder
			);

			const request: DataframeDataRetrievalRequest = {
				dataframeId: dataframe ? dataframe.id : 0,
				begin_date: datePeriodSelectorOverride?.begin_date ?? selectedDate?.begin_date ?? 0,
				end_date: datePeriodSelectorOverride?.end_date ?? selectedDate?.end_date ?? 0,
				periodId: datePeriodSelectorOverride?.period ?? selectedDate?.period ?? 0,
				...(!isEqual(overrideRequest, newOverrideRequest) && {
					override: newOverrideRequest,
				}),
			};
			setPrevRetrievalRequest(request);

			const requestsAreEqual = _.isEqual(request, prevRetrievalRequest);

			if (!requestsAreEqual) {
				Dataframe.Retrieve(request)
					.then((response: MultipartResponse<DataframeDataRetrievalResponse>): void => {
						setPreviewData(response);
						setPreviewIsLoading(false);
						setTimeout(() => {
							setHasReportLoaded(true);
						}, 900);
					})
					.catch((): void => {
						addToast('Error fetching preview data.', AlertVariant.danger);
						setPreviewIsLoading(false);
					})
					.finally(() => {
						setIsLoading(false);
						setSubSide({ isLoading: false });
					});
			} else {
				setIsLoading(false);
				setSubSide({ isLoading: false });
				setPreviewIsLoading(false);
				setTimeout(() => {
					setHasReportLoaded(true);
				}, 900);
			}
		}
	};

	const getReportSharedPermissions = () => {
		let userSharedRecord: { canEdit: boolean; canShare: boolean } = {
			canEdit: false,
			canShare: false,
		};
		if (!report || currentUser.id === report?.owner) {
			userSharedRecord = {
				canEdit: true,
				canShare: true,
			};
		} else {
			report?.sharedReport?.map((sc) => {
				const thisUser = (sc as TSharedEntity).shared_entity_users?.find(
					(u) => u.user === currentUser.id
				);
				const thisGroup = (sc as TSharedEntity).shared_entity_groups?.find(
					(u) => currentUser.groups.findIndex((ug) => ug === u.group) > -1
				);
				if (thisUser) {
					userSharedRecord = { canEdit: thisUser.can_edit, canShare: thisUser.can_share };
				} else if (thisGroup) {
					userSharedRecord = {
						canEdit: thisGroup.can_edit,
						canShare: thisGroup.can_share,
					};
				}
			});
		}

		return userSharedRecord;
	};

	const applyDrilldown = (name: string, isKMF: boolean, col?: string) => {
		if (!isKMF && drillRows.find((row) => row.data?.title.replace(/\s/g, '') === col)) {
			const exists = drillFilters.find(
				(filter) => filter.data?.title.replace(/\s/g, '') === col
			);

			if (exists) {
				const indexDrillRow = drillRows.findIndex(
					(item) => item.data?.title.replace(/\s/g, '') === col
				);

				const indexDrillFilter = drillFilters.findIndex(
					(item) => item.data?.title.replace(/\s/g, '') === col
				);

				setDrillRows(drillRows.slice(0, indexDrillRow + 1));
				setDrillFilters(drillFilters.slice(0, indexDrillFilter));
			} else {
				const drillItems = rows.filter((row) => row.data?.drillable);
				const currentIndex = drillItems.findIndex(
					(item) => item.data?.title.replace(/\s/g, '') === col
				);
				const drillItem = drillItems[currentIndex];

				if (drillItem.entityType === OptionsBuilderItemTypes.Dimension) {
					void Dimension.RetrievalWithType(drillItem.data?.id ?? 0).then((response) => {
						let newFilterItem: DraggableMenuItemData | null = null;
						const matchingFilterValue = response.items.find(
							(x) => x.value.toLowerCase() === name.toLowerCase()
						);

						newFilterItem = populateDroppedFilterByDimension(
							drillItem.data?.id ?? 0,
							dimensions
						);

						if (newFilterItem && newFilterItem.data) {
							newFilterItem.data.title = drillItem.data?.title ?? '';
							newFilterItem.data.drillable = false;
							newFilterItem.data.operator = FilterOperatorEnum.EQUALS;
							newFilterItem.data.value = matchingFilterValue?.id.toString();
							newFilterItem.data.isExistingValue = true;
							setDrillFilters([...drillFilters, newFilterItem]);

							if (drillItems[currentIndex + 1]) {
								setDrillRows([...drillRows, drillItems[currentIndex + 1]]);
							}
						}
					});
				} else if (drillItem.entityType === OptionsBuilderItemTypes.DimensionAttribute) {
					void DimensionAttribute.Retrieval(drillItem.data?.id ?? 0).then((response) => {
						let newFilterItem: DraggableMenuItemData | null = null;
						const matchingFilterValue = response.find(
							(x) => x.value.toLowerCase() === name.toLowerCase()
						);

						newFilterItem = populateDroppedFilterByDimensionAttribute(
							drillItem.data?.id ?? 0,
							dimensions
						);
						if (newFilterItem && newFilterItem.data) {
							newFilterItem.data.title = drillItem.data?.title ?? '';
							newFilterItem.data.drillable = false;
							newFilterItem.data.operator = FilterOperatorEnum.EQUALS;
							newFilterItem.data.value = matchingFilterValue?.id.toString();
							newFilterItem.data.isExistingValue = true;
							setDrillFilters([...drillFilters, newFilterItem]);

							if (drillItems[currentIndex + 1]) {
								setDrillRows([...drillRows, drillItems[currentIndex + 1]]);
							}
						}
					});
				} else if (drillItem.entityType === OptionsBuilderItemTypes.DateSeries) {
					if (selectedDate) {
						if (datePeriodSelectorOverride) {
							setDatePeriodSelectorDisabledMessage(undefined);
							setDatePeriodSelectorLabel(undefined);
							setDatePeriodSelectorOverride(undefined);
						} else {
							setDatePeriodSelectorDisabledMessage(
								'Please drill out if you would like to change the date filter.'
							);
							setDatePeriodSelectorLabel(name);
							setDatePeriodSelectorOverride(
								DatePeriodReformatter(name, selectedDate.period)
							);
						}
					}
				}
			}
		}
	};

	const updateConditionalFormattingRules = (rules: ConditionalRule[]) => {
		setConditionalFormattingRules(
			rules.map((rule) => {
				return {
					...rule,
					...{ report: report?.id ?? 0 },
				};
			})
		);
	};

	const builderProps: DataBuilderPropsV2 = {
		dataframe,
		parentDataframe,
		formLabel: props.type === DataBuilderTypes.report ? 'Report Name' : 'Table Name',
		onSave,
		onPreview,
		isEdit,
		isView,
		measures,
		dimensions,
		unitTypes,
		periods,
		folders,
		selectedDate,
		setSelectedDate,
		facts,
		rows,
		columns,
		filters,
		setFacts,
		setRows,
		setColumns,
		setFilters,
		name: name ?? '',
		setName: setName,
		folder,
		setFolder,
		sidebarHeader: 'Add Data',
		sidebarSubheader: `Drag & drop data to add it to your ${
			props.type === DataBuilderTypes.report ? 'report' : 'table'
		}`,
		validated,
		setValidated,
		type: props.type,
		disablePreviewBtn,
		setHasChanges: setDataframeChanged,
		showLimitWarning: previewData?.json?.result_size?.limitReached,
		limitDimensionOptions: queryParams.dataframeId ? true : report?.is_from_dataframe,
		sharedPermissions: getReportSharedPermissions(),
		previewData,
		favorite: favorite,
		setFavorite: getFavoriteData,
		report,
		isWidgetSave: !!dashboardId,
		datePeriodSelectorLabel,
		datePeriodSelectorDisabledMessage,
		conditionalFormattingRules,
		updateConditionalFormattingRules,
		hideFolderSelection: isView,
	};

	const previewProps: IPreviewProps = {
		data: previewData,
		facts,
		rows:
			props.type === DataBuilderTypes.table
				? [...rows.filter((row) => !row.data?.drillable), ...drillRows]
				: rows,
		columns,
		unitTypes,
		isLoading: isPreviewLoading,
		setIsLoading: setPreviewIsLoading,
		order: order ?? dataframe?.order?.[0] ?? undefined,
		setOrder,
		...(props.type === DataBuilderTypes.table && {
			ApplyDrilldownDrillIn: applyDrilldown,
			drillFilters,
			drillRows,
			hasDateDrill: !!datePeriodSelectorOverride,
		}),
		conditionalRules: conditionalFormattingRules,
		chartLoaded: hasReportLoaded,
	};

	const getBuilderTemplate = () => {
		if (isEdit || isCreate) {
			return (
				<>
					{isLoading ? (
						<Loader />
					) : (
						<div>
							{isCopy && (
								<>
									<div className="pf-c-alert pf-m-inline pf-m-info">
										<p className="pf-c-alert__title display-inline">
											<FontAwesomeIcon
												size="2x"
												icon={faInfoCircle}
												className="bolder"
											/>
											<span className="nudge-right"></span>
											<div className="fs-large">
												{`Note! You are currently creating a copy of the ${
													props.type == DataBuilderTypes.table
														? 'table'
														: 'report'
												} '${report?.name ?? ''}'`}
											</div>
										</p>
									</div>
									<br />
								</>
							)}
							<ZiDataBuilderV2 {...builderProps} />
						</div>
					)}
				</>
			);
		} else if (isReportView) {
			return (
				<>
					{isLoading ? (
						<Loader />
					) : (
						<ViewReport
							folder={folder}
							folders={folders}
							name={name}
							onRunReport={onPreview}
							parameters={parameters}
							setParameters={setParameters}
							previewData={previewData}
							setSelectedDate={setSelectedDate}
							isTable={props.type == DataBuilderTypes.table}
							reportId={report?.id ?? 0}
							userId={currentUser.id}
						/>
					)}
				</>
			);
		}
	};

	return (
		<Card className="report-container">
			<CardBody>
				{getBuilderTemplate()}
				{showPreview && !isLoading && <Preview {...previewProps} />}
			</CardBody>
		</Card>
	);
};

export default BuildReport;
