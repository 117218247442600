import {
	faShareNodes,
	faDownToBracket,
	faStar,
	faSensorAlert,
	faPencil,
} from '@fortawesome/pro-regular-svg-icons';
import { faStar as faStarSolid } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AlertVariant, Button, Flex, FlexItem } from '@patternfly/react-core';
import { useEffect, useState } from 'react';
import ShareEntityModal from './ShareEntityModal';
import { useApplication, useIsGranted } from '../../components/user/ApplicationProvider';
import { Permission } from '../../enums/permission.enum';
import CsvDownloader from 'react-csv-downloader';
import { MultipartResponse, rebuildCsvStringArray } from '../multipart-response.helper';
import { DataframeDataRetrievalResponse } from '../../api/types';
import { Columns, Datas } from 'react-csv-downloader/dist/esm/lib/csv';
import { useToast } from '@zeroedin-tech/zi-common-ui/lib';
import { dateToYYYYMMDDHHMMSS } from '../../utilities';
import { Favorites, TFavorites } from '../../api/favorites/Favorites';
import { DataBuilderTypes } from '../../enums/data-builder-types';
import AlertDefinitionModal from './AlertDefinitionModal';
import { DraggableMenuItemData } from '../../types/databuilder/databuilder';
import { useNavigate, useLocation } from 'react-router-dom';
import { TKeyMeasure } from '../../api/analytics/KeyMeasure';

type Props = {
	entityType: string;
	entityName: string;
	canShare: boolean;
	exportData?: MultipartResponse<DataframeDataRetrievalResponse>;
	limitReached?: boolean;
	favorite?: TFavorites;
	setFavorite?: () => void;
	droppedFacts?: DraggableMenuItemData[];
	isView: boolean;
};

function EntityMiscButtons(props: Props) {
	const [isOpenShareModal, setIsOpenShareModal] = useState(false);
	const [isOpenAlertModal, setIsOpenAlertModal] = useState(false);
	const isGranted = useIsGranted();
	const { addToast } = useToast();
	const [exportColumns, setExportColumns] = useState<Columns>();
	const [exportData, setExportData] = useState<Datas>();
	const [exportFileName, setExportFileName] = useState<string>();
	const navigate = useNavigate();
	const pathname = useLocation().pathname;
	const isChart = props.entityType.toLowerCase() == DataBuilderTypes.chart;
	const isDataframe = props.entityType.toLowerCase() == DataBuilderTypes.dataframe;
	const isReport = props.entityType.toLowerCase() == DataBuilderTypes.report;
	const isTable = props.entityType.toLowerCase() == DataBuilderTypes.table;
	const haseditDataframePerm = isGranted(Permission.EditDataframe);
	const haseditChartPerm = isGranted(Permission.EditChart);
	const haseditReportPerm = isGranted(Permission.EditReport);
	const haseditTablePerm = isGranted(Permission.EditTable);
	const canAccessAlerts = isGranted(Permission.CreateAlert) || isGranted(Permission.EditAlert);
	const editDataframeChecks = isDataframe && haseditDataframePerm;
	const editChartChecks = isChart && haseditChartPerm;
	const editReportChecks = isReport && haseditReportPerm;
	const editTableChecks = isTable && haseditTablePerm;
	const { measures } = useApplication();
	const [filteredKeyMeasures, setFilteredKeyMeasures] = useState<TKeyMeasure[]>([]);

	useEffect(() => {
		const droppedFactsKeyValue = props.droppedFacts?.map((x) => ({
			id: x.data?.id,
			value: x.data?.title,
		}));

		const result = measures
			.map((keymeasure) => {
				// Filter the KeyMeasureFacts to only include those that match droppedFactsKeyValue
				const filteredFacts = keymeasure.keyMeasureFacts.filter((fact) =>
					droppedFactsKeyValue?.some((droppedFact) => droppedFact.id === fact.id)
				);

				filteredFacts.map((fact) => {
					const matchingFact = droppedFactsKeyValue?.find((x) => x.id == fact.id);

					if (matchingFact && matchingFact.value) {
						fact.name = matchingFact.value;
					}
				});

				// Return the keymeasure only if there are matching facts
				return filteredFacts.length > 0
					? { ...keymeasure, keyMeasureFacts: filteredFacts }
					: null;
			})
			.filter((keymeasure) => keymeasure !== null) as TKeyMeasure[]; // Remove any keymeasures without matching facts

		setFilteredKeyMeasures(result);
	}, [props.droppedFacts]);

	useEffect(() => {
		if (props.exportData && props.exportData.json && props.exportData.csvData) {
			setExportColumns(
				props.exportData.json.columns[props.exportData.json.columns.length - 1].map(
					(col) => {
						return {
							id: col.replace(/\s/g, ''),
							displayName: `"${col}"`,
						};
					}
				)
			);

			setExportData(
				props.exportData.csvData.map((data) => {
					return rebuildCsvStringArray(data, false);
				})
			);

			setExportFileName(`${props.entityName}_${dateToYYYYMMDDHHMMSS(new Date())}`);
		} else {
			setExportColumns(undefined);
			setExportData(undefined);
			setExportFileName(undefined);
		}
	}, [props.exportData]);

	const closeShareModal = () => {
		setIsOpenShareModal(false);
	};

	const closeAlertModal = () => {
		setIsOpenAlertModal(false);
	};

	const openShareModal = () => {
		setIsOpenShareModal(true);
	};

	const openAlertModal = () => {
		setIsOpenAlertModal(true);
	};

	const checkPermissionAndNavEdit = () => {
		if (editDataframeChecks || editReportChecks || editTableChecks) {
			const editUrl = pathname.replace('/view/', '/edit/');
			navigate(editUrl);
		}
		if (editChartChecks) {
			const isDashboardChart = pathname.includes('/dashboards/');

			if (isDashboardChart) {
				const editUrl = pathname.replace('/view', '');
				navigate(editUrl);
			} else {
				const editUrl = pathname.replace('/view/', '/build/');
				navigate(editUrl);
			}
		}
	};

	const exportButtonTemplate = () => {
		const template = (
			<Button
				variant="plain"
				aria-label="Download Entiy"
				onClick={(e) => {
					if (exportData) {
						if (props.limitReached) {
							addToast(
								'More than 500 rows are present in the dataset. Apply filters to narrow your selection and reduce the number of rows.',
								AlertVariant.danger
							);
						}
					} else if (!exportData || !exportColumns) {
						addToast('Please preview data before exporting it.', AlertVariant.danger);
					} else {
						e.preventDefault();
					}
				}}
			>
				<FontAwesomeIcon
					icon={faDownToBracket}
					className="download-icon"
					size="xl"
					title="Download"
				/>
			</Button>
		);

		if (exportData && exportColumns && !props.limitReached) {
			return (
				<CsvDownloader
					columns={exportColumns}
					datas={exportData}
					filename={exportFileName ?? ''}
					extension=".csv"
					separator=","
				>
					{template}
				</CsvDownloader>
			);
		}

		return template;
	};

	const toggleFavorite = () => {
		if (props.favorite) {
			Favorites.ToggleFavorite(props.favorite)
				.then(() => {
					if (props.setFavorite) {
						props.setFavorite();
					}
				})
				.catch(() => {
					addToast('An error occured while saving a Favorite', AlertVariant.danger);
				});
		}
	};

	if (!isGranted(Permission.viewSharedEntity)) {
		return null;
	}

	//TODO: Add back once comment feature is completed
	// const commentsItem = (
	// 	<FlexItem>
	// 		<Button
	// 			variant="plain"
	// 			aria-label="Note Entity"
	// 			data-testid="note-entity"
	// 		>
	// 			<FontAwesomeIcon
	// 				icon={faMessage}
	// 				className="note-icon"
	// 				size="xl"
	// 				title="Comments"
	// 			/>
	// 		</Button>
	// 	</FlexItem>
	// );

	return (
		<div>
			<Flex
				className="lbl-entity-actions"
				flex={{ default: 'flex_1' }}
			>
				<Flex
					spaceItems={{ default: 'spaceItemsNone' }}
					align={{ default: 'alignRight' }}
				>
					{(editDataframeChecks ||
						editChartChecks ||
						editReportChecks ||
						editTableChecks) &&
						props.isView && (
							<FlexItem>
								<Button
									variant="plain"
									aria-label="Edit"
									data-testid="edit"
									onClick={() => {
										checkPermissionAndNavEdit();
									}}
								>
									<FontAwesomeIcon
										icon={faPencil}
										size="xl"
										title="Edit"
									/>
								</Button>
							</FlexItem>
						)}
					{isChart && canAccessAlerts && (
						<FlexItem>
							<Button
								variant="plain"
								aria-label="Set Alert"
								data-testid="set-alert"
								onClick={() => {
									openAlertModal();
								}}
							>
								<FontAwesomeIcon
									icon={faSensorAlert}
									size="xl"
									title="Set Alert"
								/>
							</Button>
						</FlexItem>
					)}
					{props.favorite && (
						<FlexItem>
							<Button
								variant="plain"
								aria-label="Favorite Entity"
								data-testid="favorite-entity"
								onClick={() => {
									toggleFavorite();
								}}
							>
								<FontAwesomeIcon
									icon={props.favorite.id ? faStarSolid : faStar}
									className="favorite-icon"
									size="xl"
									title={
										props.favorite.id ? 'Remove Favorite' : 'Save as Favorite'
									}
								/>
							</Button>
						</FlexItem>
					)}
					{props.canShare && (
						<FlexItem>
							<Button
								variant="plain"
								aria-label="Share Entity"
								data-testid="share-entity"
								onClick={() => {
									openShareModal();
								}}
							>
								<FontAwesomeIcon
									icon={faShareNodes}
									className="share-icon"
									size="xl"
									title="Share"
								/>
							</Button>
						</FlexItem>
					)}
					{(isChart || isTable || isDataframe) && (
						<FlexItem>{exportButtonTemplate()}</FlexItem>
					)}
				</Flex>
			</Flex>

			<ShareEntityModal
				entityType={props.entityType}
				entityName={props.entityName}
				isOpen={isOpenShareModal}
				onClose={closeShareModal}
			/>
			<AlertDefinitionModal
				isOpen={isOpenAlertModal}
				onClose={closeAlertModal}
				droppedFacts={props.droppedFacts ?? []}
				filteredKeyMeasures={filteredKeyMeasures}
			/>
		</div>
	);
}

export default EntityMiscButtons;
