import React, { Dispatch, SetStateAction } from 'react';
import { Form, Grid, GridItem, TextInput } from '@patternfly/react-core';
import { TFolder } from '../../../api/foundational-elements/Folder';
import './DefaultPresentOptionsView.scss';
import { PresentCreationOptions } from './ZiPresentOptionsModal';
import { TPresent } from '../../../api/present/Present';
import SelectFolderDropdown from '../../../helpers/helper-components/SelectFolderDropdown';

export type BlankPresentOptionsViewProps = {
	onViewChange: Dispatch<SetStateAction<PresentCreationOptions>>;
	presentation: TPresent;
	folders: TFolder[];
	handleNameChange: (value: string, event: React.FormEvent<HTMLInputElement>) => void;
	selectedFolder?: TFolder;
	onSelectFolder: Dispatch<SetStateAction<TFolder | undefined>>;
};

const BlankPresentOptionsView = (props: BlankPresentOptionsViewProps) => {
	const { presentation, folders, handleNameChange, onSelectFolder, selectedFolder } = props;

	return (
		<div
			className="component-container"
			data-testid="default-view"
		>
			<Form>
				<Grid>
					<GridItem span={12}>
						<TextInput
							placeholder="Presentation Name"
							value={presentation.name}
							onChange={handleNameChange}
						/>
					</GridItem>
					<SelectFolderDropdown
						folderId={presentation.folder as number}
						folders={folders}
						onFolderSelect={(e, item) => {
							onSelectFolder(folders.find((f) => f.id.toString() === item.id));
						}}
					/>
				</Grid>
			</Form>
		</div>
	);
};

export default BlankPresentOptionsView;
